import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_import_outlined = _resolveComponent("import-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_search_table = _resolveComponent("search-table")!
  const _component_form_modal = _resolveComponent("form-modal")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _directive_perm = _resolveDirective("perm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_search_table, { opts: _ctx.tableOpts }, {
      button: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_a_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stockOpts.visible = true))
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_import_outlined)
          ]),
          default: _withCtx(() => [
            _createTextVNode("入库")
          ]),
          _: 1
        })), [
          [_directive_perm, void 0, "stockIn"]
        ])
      ]),
      _: 1
    }, 8, ["opts"]),
    _createVNode(_component_form_modal, { opts: _ctx.stockOpts }, null, 8, ["opts"]),
    _createVNode(_component_a_drawer, {
      closable: false,
      visible: _ctx.detailOpts.visible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailOpts.visible) = $event)),
      placement: "left",
      width: "25%",
      title: "入库明细"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_list, {
          size: "small",
          bordered: "",
          "data-source": _ctx.detailOpts.list
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.sn), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}