
import { defineComponent, reactive } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';
import { FormItemOptions } from '@/types/form.config';

import { ImportOutlined } from '@ant-design/icons-vue';
import SearchTable from '@/components/table/SearchTable.vue';
import FormModal from '@/components/composite/FormModal.vue';
import { useGetters } from '@/store/mixin';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'StockIn',
  components: { FormModal, SearchTable, ImportOutlined },
  setup() {
    const { https, mapper, message } = useInject();
    const { userMeta } = useGetters();
    const stockOpts = reactive({
      title: '机具入库',
      visible: false,
      action: https?.device.stockIn,
      content: {
        tips: {
          type: 'warning',
          msg: '注意事项：测试机具号12位，正式机具号15位'
        },
        cols: [
          {
            field: 'stockType',
            label: '入库方式',
            type: 'radio',
            style: 'outline',
            defVal: '1',
            options: mapper?.device.stockType,
            required: true,
            changeNotify: true,
          },
          {
            field: 'startSn',
            label: '起始编号',
            required: true,
            visible: true
          },
          {
            field: 'endSn',
            label: '结束编号',
            required: true,
            visible: true
          },
          {
            field: 'sns',
            label: '机具编号',
            type: 'textarea',
            required: true,
            visible: false
          },
          {
            field: 'posType',
            label: '设备型号',
            type: 'select',
            mapper: ['codeName', 'codeName'],
            remote: {
              params: { type: constants.enums.enumType.deviceType },
              action: https?.mall.listEnum
            },
            required: true
          },
          {
            field: 'machType',
            label: '设备类型',
            required: true,
            type: 'select',
            mapper: ['posType', 'posTypeTag'],
            remote: {
              action: https?.device.listModelType
            }
          },
          {
            field: 'backerNo',
            label: '品牌名称',
            type: 'select',
            options: mapper?.device.backerNo,
            required: true
          },
          {
            field: 'mark',
            label: '机具标识',
            type: 'radio',
            options: mapper?.device.markTypes,
            required: true
          },
          {
            field: 'remark',
            label: '备注',
            type: 'textarea'
          },
          { field: 'operator', defVal: userMeta.uid, visible: false },
        ],
        handleValueChange(field: string, entity: DataItem, cols: FormItemOptions[]) {
          if (field === 'stockType') {
            cols.forEach(c => {
              if (c.field === 'startSn' || c.field === 'endSn') {
                c.visible = entity[field] === '1';
              }

              if (c.field === 'sns') {
                c.visible = entity[field] === '2';
              }
            });
          }
        },
        evaluate(data: DataItem) {
          // 将回车换行替换为逗号，如果原本有逗号，再将两个逗号替换为一个逗号
          if (data.sns) {
            let sns: string = data.sns.replace(/[\r\n]/g, ',').replace(/,,/g, ',');
            if (sns.endsWith(',')) {
              sns = sns.substring(0, sns.length - 1);
            }
            data.sns = sns;
          }
          return data;
        }
      }
    });

    const detailOpts = reactive({
      visible: false,
      list: []
    });

    const tableOpts: DataItem = {
      search: {
        criteria: {
          cols: [
            {
              field: 'no',
              label: '入库单号'
            },
            {
              field: 'backerNo',
              label: '品牌',
              type: 'select',
              options: mapper?.device.backerNo
            }
          ]
        },
        remote: {
          action: https?.device.pageStockIn
        }
      },
      cols: [
        {
          field: 'no',
          label: '入库单号',
          ability: 'copy'
        },
        {
          field: 'startSn',
          label: '开始编号',
          width: 160
        },
        {
          field: 'endSn',
          label: '结束编号',
          width: 160
        },
        {
          field: 'quantity',
          label: '入库数量',
          width: 140
        },
        {
          field: 'posType',
          label: '机具型号',
          width: 140
        },
        {
          field: 'backerNo',
          label: '品牌号',
          options: mapper?.device.backerNo,
          width: 140
        },
        {
          field: 'remark',
          label: '备注',
        },
        {
          field: 'createTime',
          label: '入库时间',
          width: 200
        }
      ]
    };

    const openDetail = (row: DataItem) => {
      https?.device.listStockInDetail({ no: row.no, type: '1' }).then(res => {
        if (res.respType === 'OK') {
          if (res.respData.length > 0) {
            detailOpts.list = res.respData;
            detailOpts.visible = true;
          }
        } else {
          message?.error('查询入库明细失败');
        }
      });
    };

    tableOpts.commands = [
      { label: '明细', action: openDetail }
    ];
    return { stockOpts, detailOpts, tableOpts };
  }
});
